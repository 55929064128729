/**
 *
 * Product Prices
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import FlatIcon from '../../Icon/FlatIcon'
import PackingDisplayValue from './PackingDisplayValue'
import { useCustomer } from '../../../utils/hook/useCustomer'
import { CatalogUnitType } from '../../../services/api/service/core/types'

interface IProps {
    packing: number
    showLabel: boolean
    showIcon: boolean
    abbreviateLabels: boolean
    abbreviateValues: boolean
    unitOfSale?: CatalogUnitType
    as: React.ElementType
    className?: string
}

function Packing({
    packing,
    as,
    abbreviateLabels,
    abbreviateValues,
    showLabel,
    showIcon,
    className,
    unitOfSale,
}: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const customer = useCustomer()

    const labelIdentifier = useMemo(() => {
        if (unitOfSale === CatalogUnitType.Meter) {
            return abbreviateLabels ? 'default.metering_abbr' : 'default.metering'
        }
        return abbreviateLabels ? 'product.packing_abbr' : 'product.packing'
    }, [unitOfSale, abbreviateLabels])

    const titleIdentifier = useMemo(() => {
        if (unitOfSale === CatalogUnitType.Meter) {
            return 'default.metering'
        }
        return 'product.packing'
    }, [unitOfSale])

    const Child = (
        <>
            {showLabel && (
                <span className={'lbl'} title={formatMessage({ id: titleIdentifier })}>
                    <FormattedMessage id={labelIdentifier} />
                </span>
            )}
            <span className={'value'}>
                <PackingDisplayValue unitOfSale={unitOfSale} abbreviate={abbreviateValues} packing={packing} />
            </span>
            {showIcon && <FlatIcon icon={'box'} />}
        </>
    )

    // Dans le cas où le client ne peux pas commander par packing, peu import la configuration du composant, on s'arrête ici !
    if (customer?.order_by_unit) {
        return <></>
    }

    return React.createElement(
        as || 'div',
        {
            className: classNames('product-packing', className),
        },
        Child
    )
}

Packing.defaultProps = {
    abbreviateLabels: true,
    abbreviateValues: true,
    showLabel: true,
    showIcon: false,
    unitOfSale: CatalogUnitType.Unit,
    as: 'div',
} as Partial<IProps>

export default memo(Packing)
